import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Content from '@solid-ui-blocks/Content/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import theme from './_theme'

const Cookie = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <div style={{ textAlign: 'center', paddingRight: '5rem', paddingLeft: '5rem' }}>
      <div>
    <h2>Zelten LLC Cookies Policy</h2>

    <p><strong>Effective Date:</strong> 12/20/2023</p>

    <p>At Zelten LLC, we use cookies to enhance your experience on our website. This Cookies Policy explains how we use cookies and your choices regarding their use.</p>

    <p><strong>What are Cookies:</strong></p>
    <p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the service or a third-party to recognize you and make your next visit easier and more useful to you.</p>

    <p><strong>How We Use Cookies:</strong></p>
    <p>We use cookies for various purposes, including:</p>
    <ul>
      <li>To provide and maintain our services.</li>
      <li>To understand how you interact with our website.</li>
      <li>To improve and personalize your experience.</li>
      <li>To provide analytics and gather valuable information for improving our services.</li>
    </ul>

    <p><strong>Your Choices:</strong></p>
    <p>You have the option to either accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our services.</p>

    <p><strong>Third-Party Cookies:</strong></p>
    <p>We may use third-party services that may collect information used to identify you. Please review their respective privacy policies for more information.</p>

    <p><strong>Changes to Our Cookies Policy:</strong></p>
    <p>We may update our Cookies Policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>

    <p>By using our website, you consent to the use of cookies in accordance with this Cookies Policy.</p>

    <p>If you have any questions about our Cookies Policy, please contact us at <a href="mailto:info@zeltenstudio.com">info@zeltenstudio.com</a>.</p>

    <p>Thank you for visiting Zelten LLC.</p>

  </div>

        </div>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageCookieBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Cookie
